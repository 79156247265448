exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */),
  "slice---src-components-preload-clip-path-tsx": () => import("./../../../src/components/Preload/ClipPath.tsx" /* webpackChunkName: "slice---src-components-preload-clip-path-tsx" */),
  "slice---src-components-preload-preload-tsx": () => import("./../../../src/components/Preload/Preload.tsx" /* webpackChunkName: "slice---src-components-preload-preload-tsx" */)
}

